import { createRouter , createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'index',
    component:() => import('../views/home/index.vue'),
    meta: {
      title: '导航',
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
