import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

router.beforeEach((to,form,next)=>{
    if(to.meta.title){
        document.title=to.meta.title;
    }
    next();
})

const app = createApp(App).use(router);
app.use(router).mount('#app')